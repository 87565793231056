import React, { useEffect, useRef, useState } from 'react';
import { ArrowUpIcon, ArrowDownIcon } from 'lucide-react';
import { useMarketData } from './MarketDataProvider';

const MarketTicker = () => {
    const scrollRef = useRef(null);
    const contentRef = useRef(null);
    const [scrollSpeed, setScrollSpeed] = useState(1);
    const { marketData, loading, error } = useMarketData();

    // Screen size effect
    useEffect(() => {
        const updateScrollSpeed = () => {
            const width = window.innerWidth;
            if (width <= 768) {
                setScrollSpeed(0.3);
            } else if (width <= 1024) {
                setScrollSpeed(0.6);
            } else {
                setScrollSpeed(1);
            }
        };

        updateScrollSpeed();
        window.addEventListener('resize', updateScrollSpeed);
        return () => window.removeEventListener('resize', updateScrollSpeed);
    }, []);

    // Scroll effect
    useEffect(() => {
        if (!marketData?.length) return;

        const scrollContainer = scrollRef.current;
        const contentWidth = contentRef.current?.offsetWidth / 4;
        let scrollPos = 0;
        let animationFrameId;

        const scroll = () => {
            if (scrollContainer && contentWidth) {
                scrollPos += scrollSpeed;
                if (scrollPos >= contentWidth) {
                    scrollPos = 0;
                    scrollContainer.scrollLeft = 0;
                } else {
                    scrollContainer.scrollLeft = scrollPos;
                }
                animationFrameId = requestAnimationFrame(scroll);
            }
        };

        animationFrameId = requestAnimationFrame(scroll);

        return () => {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };
    }, [scrollSpeed, marketData]);

    // Format price based on symbol type
    const formatPrice = (price, symbol) => {
        if (!price) return 'N/A';

        // Clean the price string by removing commas
        const cleanPrice = price.toString().replace(/,/g, '');

        switch (symbol) {
            case 'EUR/USD':
                return `€${price}`;
            case 'BTC/USD':
            case 'Bitcoin':
                // Parse the clean price and format with commas
                return `$${Number(cleanPrice).toLocaleString()}`;
            case 'Gold':
            case 'Silver':
                return `$${price}/oz`;
            case 'US10Y':
                return `${price}%`;
            case 'FTSE 100':
                return `£${price}`;
            default:
                return `$${price}`;  // Keep original format for indices
        }
    };

    // Format symbol display names
    const formatSymbol = (symbol) => {
        if (!symbol) return 'N/A';
        switch (symbol) {
            case 'NASDAQ': return 'NASDAQ';
            case 'S&P 500': return 'SPX';
            case 'DXY': return 'DXY';
            case 'Nikkei 225': return 'Nikkei 225';
            case '000001.SS': return 'Shanghai';
            case 'GC=F': return 'Gold';
            case 'SI=F': return 'Silver';
            case 'US10Y': return '10Y Treasury';
            case 'BTC/USD': return 'Bitcoin';
            case 'FTSE 100': return 'FTSE 100';
            case 'DOW': return 'Dow Jones';
            default: return symbol;
        }
    };

    if (loading) {
        return (
            <div className="ticker-container">
                <div className="ticker-wrapper">
                    <div className="ticker-content">
                        <span className="ticker-item">
                            <span className="ticker-symbol">Loading market data...</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="ticker-container">
                <div className="ticker-wrapper">
                    <div className="ticker-content">
                        <span className="ticker-item">
                            <span className="ticker-symbol">{error}</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    // Filter market data to only include ticker items (exclude fear-and-greed)
    const validMarketData = marketData?.filter(item => {
        return item &&
            item.symbol &&
            item.price &&
            item.change &&
            !item.type; // Exclude items with a type property (fear-and-greed)
    }) || [];

    return (
        <div className="ticker-container">
            <div className="ticker-wrapper" ref={scrollRef}>
                <div className="ticker-content" ref={contentRef}>
                    {[...validMarketData, ...validMarketData, ...validMarketData, ...validMarketData].map((item, index) => (
                        <span key={index} className="ticker-item">
                            <span className="ticker-symbol">{formatSymbol(item.symbol)}</span>
                            <span className="ticker-price">
                                {formatPrice(item.price, item.symbol)}
                            </span>
                            <span className={`ticker-change ${item.isPositive ? 'positive' : 'negative'}`}>
                                {item.isPositive ? (
                                    <ArrowUpIcon className="ticker-icon" />
                                ) : (
                                    <ArrowDownIcon className="ticker-icon" />
                                )}
                                {item.change}
                            </span>
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MarketTicker;