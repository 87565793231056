import { useEffect, useRef } from 'react';

function TradingViewWidget() {
    const container = useRef();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.async = true;

        script.onload = () => {
            if (typeof window.TradingView !== 'undefined') {
                new window.TradingView.chart({
                    container_id: "tradingview_widget",
                    chart: "g28LhMts",
                    width: "100%",
                    height: "100%",
                    autosize: true,
                    symbol: "SPY",
                    interval: "D",
                    timezone: "Etc/UTC",
                    theme: "dark",
                    style: "1",
                    locale: "en",
                    toolbar_bg: "#f1f3f6",
                    enable_publishing: false,
                    save_image: false,
                    hide_top_toolbar: true,
                    hide_side_toolbar: true,
                    hide_legend: true,
                    hide_volume: true,
                    hide_drawing_toolbar: true,
                    hide_floating_toolbar: true,
                    details: false,
                    hotlist: false,
                    calendar: false,
                    show_popup_button: false,
                    withdateranges: false,
                    allow_symbol_change: true
                });
            }
        };

        container.current.appendChild(script);

        return () => {
            if (container.current) {
                container.current.removeChild(script);
            }
        };
    }, []);

    return (
        <div className="tradingview-widget-container" ref={container}>
            <div
                id="tradingview_widget"
                style={{
                    width: '95%',
                    aspectRatio: '16/9',
                    minHeight: '600px',
                    maxHeight: '80vh',
                    margin: '0 auto',
                    borderRadius: '0px',
                    overflow: 'hidden',

                }}
            />
        </div>
    );
}

export default TradingViewWidget;