import { useMarketData } from './MarketDataProvider';

const FearAndGreedGauge = () => {
    const { marketData, loading, error } = useMarketData();
    const sentiment = marketData?.find(item => item.type === 'fear-and-greed');

    // Get color based on value
    const getColor = (value) => {
        if (value <= 25) return '#ef4444';
        if (value <= 45) return '#f97316';
        if (value <= 55) return '#eab308';
        if (value <= 75) return '#22c55e';
        return '#15803d';
    };

    // Get text description based on value
    const getSentimentText = (value) => {
        if (value <= 25) return 'Extreme Brokie';
        if (value <= 45) return 'McEmployment';
        if (value <= 55) return '100x Incoming';
        if (value <= 75) return 'Retard Money';
        return 'Retardation Top Signal';
    };

    const legendItems = [
        { text: 'Extreme Brokie', color: '#ef4444' },
        { text: 'McEmployment', color: '#f97316' },
        { text: '100x Incoming', color: '#eab308' },
        { text: 'Retard Money', color: '#22c55e' },
        { text: 'Retardation Top Signal', color: '#15803d'  }
    ];

    const getGaugePath = (value) => {
        const radius = 80;
        const centerX = 150;
        const centerY = 140;

        const startAngle = Math.PI;
        const endAngle = Math.PI * (1 - value / 100);

        const startX = centerX - radius;
        const startY = centerY;
        const endX = centerX + radius * Math.cos(endAngle);
        const endY = centerY - radius * Math.sin(endAngle);

        // Create individual segment paths
        const segments = [];
        const numSegments = 5;

        for (let i = 0; i < numSegments; i++) {
            const segStart = Math.PI * (1 - i / numSegments);
            const segEnd = Math.PI * (1 - (i + 1) / numSegments);
            const segStartX = centerX + radius * Math.cos(segStart);
            const segStartY = centerY - radius * Math.sin(segStart);
            const segEndX = centerX + radius * Math.cos(segEnd);
            const segEndY = centerY - radius * Math.sin(segEnd);

            segments.push(`M ${segStartX} ${segStartY} A ${radius} ${radius} 0 0 1 ${segEndX} ${segEndY}`);
        }

        return {
            background: `M ${startX} ${startY} A ${radius} ${radius} 0 1 1 ${centerX + radius} ${startY}`,
            value: `M ${startX} ${startY} A ${radius} ${radius} 0 ${value > 50 ? 1 : 0} 1 ${endX} ${endY}`,
            segments,
            startPoint: { x: startX, y: startY },
            endPoint: { x: centerX + radius, y: startY }
        };
    };

    const getNeedlePoints = (value) => {
        const radius = 80;
        const centerX = 150;
        const centerY = 140;
        const angle = Math.PI * (1 - value / 100);
        const needleLength = radius - 10;

        return {
            x1: centerX,
            y1: centerY,
            x2: centerX + needleLength * Math.cos(angle),
            y2: centerY - needleLength * Math.sin(angle)
        };
    };

    if (loading) return <div className="text-center p-4">Loading sentiment data...</div>;
    if (error) return <div className="text-center p-4 text-red-500">{error}</div>;
    if (!sentiment) return null;

    const value = sentiment.now.value;
    const paths = getGaugePath(value);
    const needle = getNeedlePoints(value);
    const colors = ['#ef4444', '#f97316', '#eab308', '#22c55e', '#15803d'];

    return (
        <div className="max-w-xl mx-auto p-6 bg-black rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-center text-white">Brokie & Retard Index</h2>
            <div className="flex flex-col md:flex-row items-start justify-between gap-4">
                <div className="w-full md:w-2/3">
                    <svg viewBox="0 0 300 260" className="w-full">
                        {/* Current value above gauge */}
                        <text
                            x="150"
                            y="35"
                            fill="#ffffff"
                            fontSize="48"
                            textAnchor="middle"
                            fontWeight="bold"
                        >
                            {value}
                        </text>

                        {/* Background gauge */}
                        <path
                            d={paths.background}
                            fill="none"
                            stroke="#333333"
                            strokeWidth="12"
                            strokeLinecap="round"
                        />

                        {/* Colored segments */}
                        {paths.segments.map((segment, index) => (
                            <path
                                key={index}
                                d={segment}
                                fill="none"
                                stroke={colors[index]}
                                strokeWidth="12"
                                strokeLinecap="round"
                            />
                        ))}

                        {/* Value labels */}
                        <g transform={`translate(${paths.startPoint.x - 25}, ${paths.startPoint.y + 25})`}>
                            <text
                                fill="#ffffff"
                                fontSize="16"
                                textAnchor="middle"
                                fontWeight="bold"
                            >
                                0
                            </text>
                        </g>

                        <g transform={`translate(${paths.endPoint.x + 25}, ${paths.endPoint.y + 25})`}>
                            <text
                                fill="#ffffff"
                                fontSize="16"
                                textAnchor="middle"
                                fontWeight="bold"
                            >
                                100
                            </text>
                        </g>

                        {/* Gauge needle */}
                        <line
                            x1={needle.x1}
                            y1={needle.y1}
                            x2={needle.x2}
                            y2={needle.y2}
                            stroke="#ffffff"
                            strokeWidth="2"
                        />
                        <circle cx={needle.x1} cy={needle.y1} r="6" fill="#ffffff" />

                        {/* Sentiment text */}
                        <text
                            x="150"
                            y="200"
                            fill={getColor(value)}
                            fontSize="18"
                            textAnchor="middle"
                            fontWeight="bold"
                        >
                            {getSentimentText(value)}
                        </text>
                    </svg>
                </div>

                {/* Legend */}
                <div className="w-full md:w-1/3">
                    <svg viewBox="0 0 225 225" className="w-full">
                        {legendItems.map((item, index) => (
                            <g key={index} transform={`translate(10, ${index * 30 + 20})`}>
                                <rect
                                    width="20"
                                    height="20"
                                    fill={item.color}
                                    rx="4"
                                />
                                <text
                                    x="30"
                                    y="15"
                                    fill="#ffffff"
                                    fontSize="14"
                                    alignmentBaseline="middle"
                                >
                                    {item.text}
                                </text>
                            </g>
                        ))}
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default FearAndGreedGauge;