import TradingViewWidget from './TradingViewWidget'
import MarketTicker from './MarketTicker'
import FearAndGreedGauge from './FearAndGreedGauge'
import MarketDataProvider from './MarketDataProvider'
import './App.css'

function App() {
    return (
        <MarketDataProvider>
            <div className="app-container">
                <MarketTicker />
                <h1 className="app-header">YOUR "NOT FINANCIAL ADVICE" $SPY ADVISOR</h1>
                <TradingViewWidget />
                <div className="mt-8">
                    <FearAndGreedGauge />
                </div>
            </div>
        </MarketDataProvider>
    )
}

export default App