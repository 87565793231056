import React, { createContext, useContext, useState, useEffect } from 'react';

const MarketDataContext = createContext(null);

export const useMarketData = () => {
    const context = useContext(MarketDataContext);
    if (!context) {
        throw new Error('useMarketData must be used within a MarketDataProvider');
    }
    return context;
};

export const MarketDataProvider = ({ children }) => {
    const [marketData, setMarketData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const API_URL = import.meta.env.PROD ? 'https://www.ctotools.com/api/market-data' : '/market-data';

    useEffect(() => {
        const fetchMarketData = async () => {
            try {
                const response = await fetch(API_URL);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                if (data.error) {
                    throw new Error(data.error);
                }
                setMarketData(data);
                setLoading(false);
            } catch (err) {
                console.error('Fetch error:', err);
                setError(`Unable to fetch market data: ${err.message}`);
                setLoading(false);
            }
        };

        fetchMarketData();
        const interval = setInterval(fetchMarketData, 5 * 60 * 1000); // 5 minutes
        return () => clearInterval(interval);
    }, []);

    const value = {
        marketData,
        loading,
        error
    };

    return (
        <MarketDataContext.Provider value={value}>
            {children}
        </MarketDataContext.Provider>
    );
};

export default MarketDataProvider;